html, body, #root {
    height: 100%;
    min-height: 100%;
}

.chakra-select-empty {
    color: var(--text-disabled);
}

.chakra-select-empty option {
    color: var(--text-enabled);
}

.disabled-option {
    color: var(--text-disabled) !important;
}

.selected-row {
    background-color: var(--green-button);
    color: var(--text-inverted);
}

.line-through {
    text-decoration:line-through;
}