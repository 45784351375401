:root {
  --react-switch-width: 40px;
  --react-switch-height: 22px;
  --react-switch-circle-size: 18px;
  --react-switch-padding: calc((var(--react-switch-height) - var(--react-switch-circle-size)) / 2);
}

.react-switch {
  display: flex;
  align-items: end;
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-bg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: var(--react-switch-width);
  height: var(--react-switch-height);
  background: var(--switch-bg-color-disabled);
  border-radius: var(--react-switch-width);
  position: relative;
  transition: background-color .2s;
}

.react-switch-label {
  padding-left: 6px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.react-switch-bg .react-switch-button {
  content: '';
  position: absolute;
  top: var(--react-switch-padding);
  left: var(--react-switch-padding);
  width: var(--react-switch-circle-size);
  height: var(--react-switch-circle-size);
  border-radius: var(--react-switch-width);
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked+.react-switch-bg .react-switch-button {
  left: calc(100% - var(--react-switch-padding));
  transform: translateX(-100%);
}

.react-switch-bg:active .react-switch-button {
  width: var(--react-switch-circle-size);
}