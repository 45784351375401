html, body, #root {
    height: 100%;
}
.main {
    height: 100%;
    width: 100%;
    display: table;
}
.wrapper {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    text-align: center;
}
.wrapper img {
    display: flex;
    align-items: center;
    margin: 0 auto 20px auto;
    width: 200px;
    height: auto;
}
.no-access {
    margin: 10px 0 0 0;
    color: #D0312D;
    font-size: 14px;
    font-weight: 600;
}
.bsk-btn {
    background-color: #2c2c2e;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 18px 50px;
    border-radius: 6px;
    margin: 0 auto;
}
.bsk-btn:hover {
    background-color: #1a1a1c;
}
.x-icon {
    border: none;
    margin-right: 10px;
    pointer-events:none;
}